<template>
  <div>
    <div class="card card-custom mb-5 mt-sm-5">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="$t('label.year')"
                :value="years.find(el => el.id === filters.year)"
                :options="years"
                @select="filters.year = $event.id"
            />
          </div>
          <div class="col-md-7">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="$t('label.apartments')"
                :value="apartments.filter(el => filters.apartments.includes(el.id))"
                :options="apartments"
                :multiple="true"
                @select="filters.apartments.push($event.id)"
                @remove="filters.apartments = filters.apartments.filter(el => el !== $event.id)"
            />
          </div>
          <div class="col-md-2">
            <button type="button" class="btn btn-success font-weight-bold w-100" @click="search">
              {{ $t('btn.update') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="text-center text-muted p-7">
          {{ $t('description.no_data') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';

export default {
  components: {
    MultiselectFormGroup
  },
  data: () => ({
    filters: {
      year: 2022,
      apartments: []
    },
    years: [...Array(1)].map((a, b) => {
      let year = new Date().getFullYear() - b;
      return {id: year, label: year}
    }),
  }),
  beforeMount() {
    this.$store.dispatch('apartmentsStore/GET_LIST');
  },
  computed: {
    ...mapGetters({
      apartmentsList: 'apartmentsStore/LIST'
    }),
    apartments() {
      return this.apartmentsList
          ? Object.entries(this.apartmentsList).map(el => {
            return el[1].map(apartment => {
              return {id: apartment.id, label: apartment.name};
            });
          })[0]
          : [];
    },
    months() {
      return [
        {id: 0, label: this.$t('month.all')},
        {id: 1, label: this.$t('month.january')},
        {id: 2, label: this.$t('month.february')},
        {id: 3, label: this.$t('month.march')},
        {id: 4, label: this.$t('month.april')},
        {id: 5, label: this.$t('month.may')},
        {id: 6, label: this.$t('month.june')},
        {id: 7, label: this.$t('month.july')},
        {id: 8, label: this.$t('month.august')},
        {id: 9, label: this.$t('month.september')},
        {id: 10, label: this.$t('month.october')},
        {id: 11, label: this.$t('month.november')},
        {id: 12, label: this.$t('month.december')}
      ];
    }
  },
  watch: {
    apartments(data) {
      this.filters.apartments = data.map(el => el.id);
      this.search();
    }
  },
  methods: {
    search() {
      this.$store.dispatch('analyticsStore/GET_REVENUE', {
        apartments: this.filters.apartments,   // [15132, 29168, 26954, 27002]
        year: this.filters.year
      });
    },
    goToBookingsList(data) {
      this.$router.push({name: 'bookingsList', query: {start_date: data.item.date + '-01'}});
    },
    showDetailsModal(data) {
      this.utilitiesModalData = data.item.utilities;
      this.$bvModal.show('utilitiesDetails');
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  position: relative;
  margin-bottom: 0 !important;

  & .form-group__label {
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    z-index: 2;
  }
}
</style>